<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(statement.behavior)[0]}-ReplacementStatement`).toUpperCase() }}
      span (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="statement.behavior?.viewInfoBlock" :statement="statement")
</template>

<script>
import { copyToClipboard, viewDetailedComponent } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.sailor.isLoading,
      statement: state => state.sailor.replacementStatementById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getReplacementStatementSailorById']),
    async getData () {
      await this.getReplacementStatementSailorById({ ...this.$route.params })
    }
  }
}
</script>
